import styled from "styled-components";
import Cart from "../../store/cart/model";
import CartProduct from "../../store/cart-product/model";
import SubscriptionBenefits from "../product/hero/SubscriptionBenefits";
import LedgerItems from "./LedgerItems";

const CartLedgerWrapper = styled.div`
  background-color: var(--white);
  padding: var(--spacing-1) var(--spacing-1_5) var(--spacing-1_5);

  @media (min-width: 750px) {
    padding-top: var(--spacing-1_5);
  }
`;

const ValuePropsWrapper = styled(CartLedgerWrapper)`
  background-color: unset;
  padding-top: 0;

  & > * {
    margin-top: var(--spacing-3);
  }
`;

const Title = styled.h2`
  margin-bottom: var(--spacing-1_5);
`;
interface CartLedgerProps {
  activeCart: Cart;
  activeCartProducts: CartProduct[];
}

const CartLedger = ({ activeCart, activeCartProducts }: CartLedgerProps) => {
  return (
    <>
      <CartLedgerWrapper>
        <Title className="typography-eyebrow1">Order Summary</Title>
        <LedgerItems activeCartProducts={activeCartProducts} />
      </CartLedgerWrapper>
      <ValuePropsWrapper>
        <SubscriptionBenefits product={activeCartProducts[0]} />
      </ValuePropsWrapper>
    </>
  );
};

export default CartLedger;
