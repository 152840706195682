import Modal from "../MagicModal";
import { AddressVerificationOutput } from "../../utils/types/validateAddressTypes";
import { addressSuggestions } from "../../utils/validateAddress";
import QAAddressDisplay from "./QAAddressDisplay";

interface PotentiallyProblematicAddressModalProps {
  suggestionModalOpen: boolean;
  onRequestClose: () => void;
  addressValidationResult: AddressVerificationOutput | void | null;
  dpvConfirmation?: string;
  unconfirmedComponentTypes?: string[] | null;
  missingComponentTypes?: string[] | null;
}

const PotentiallyProblematicAddressModal = ({
  suggestionModalOpen,
  onRequestClose,
  addressValidationResult,
  unconfirmedComponentTypes,
  missingComponentTypes,
}: PotentiallyProblematicAddressModalProps) => {
  const suggestions = addressSuggestions(
    unconfirmedComponentTypes,
    missingComponentTypes,
  );
  return (
    <Modal isOpen={suggestionModalOpen} onRequestClose={onRequestClose}>
      {addressValidationResult && (
        <QAAddressDisplay addressValidationResult={addressValidationResult} />
      )}
      {suggestions && (
        <div>
          <p>Please check {suggestions}</p>
        </div>
      )}
    </Modal>
  );
};

export default PotentiallyProblematicAddressModal;
