import styled from "styled-components";
import { Color, media, responsive } from "../../../utils/style";
import RitualLogo from "../RitualLogo";

const Wrapper = styled.div`
  z-index: 110;
  // Width excluding scrollbars.
  width: calc(100vw - (100vw - 100%));
`;

const NavContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  ${responsive.sm`
    width: calc(100vw - 48px);
  `}

  ${responsive.lg`
    width: calc(100vw - 80px);
    max-width: 1170px;
  `}
`;

const NavRow = styled.div`
  height: 100%;
  padding: 0 15px;

  ${responsive.sm`
    padding: 0;
  `}
`;

const TopNavElement = styled.nav`
  display: flex;
  border-top: 4px solid ${Color.ritualBlue};
  background-color: ${Color.white};
  z-index: 110;
  width: 100%;
  height: 52px;
  border-bottom: 1px solid ${Color.white};
  transition: border-bottom 200ms ease-in-out;
  box-sizing: content-box;

  &.scrolled {
    border-top: none;
    border-bottom: 1px solid #ccc;
  }

  ${responsive.sm`
    transition: none;

    &.scrolled {
      border-top: none;
      border-bottom: 1px solid #ccc;
    }
  `}

  ${responsive.md`
    height: 64px;
  `}
`;
const NavItemList = styled.ul`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const NavItem = styled.li`
  align-items: center;
  align-content: center;
  display: flex;
  height: 100%;
  position: relative;
`;

const LogoItem = styled(NavItem)`
  position: absolute;
  left: calc(50% - 25px);

  ${media.mobile`
    transition: left 400ms ease-in-out;
    visibility: visible;

    &.open {
      left: 6px;

      &.submenu {
        visibility: hidden;
        left: -65px;
        transition: left 400ms ease-in-out, visibility 1ms linear 400ms;
      }
    }
  `}
`;

const LogoWrapper = styled.div`
  svg {
    height: 16px;
    width: 51px;

    ${responsive.sm`
      height: 18px;
      width: 58px;
    `}

    ${responsive.md`
      height: 22px;
      width: 70px;
    `}
  }
`;

export default function SimpleNav() {
  return (
    <Wrapper>
      <TopNavElement>
        <NavContainer>
          <NavRow>
            <NavItemList>
              <LogoItem>
                <LogoWrapper>
                  <RitualLogo to="/" title="Ritual" />
                </LogoWrapper>
              </LogoItem>
            </NavItemList>
          </NavRow>
        </NavContainer>
      </TopNavElement>
    </Wrapper>
  );
}
