// cSpell:words subpremise

import axios, { AxiosResponse } from "axios";
import {
  StripeFormattedAddress,
  AddressValidationResponse,
  AddressVerificationOutput,
} from "./types/validateAddressTypes";

const GATSBY_GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY || "";

export const VALIDATE_ADDRESS_URL =
  "https://addressvalidation.googleapis.com/v1:validateAddress";

/**
 * Private function for pulling out the relevant parts of a Google Address Validation response
 */
const addressValidationVerdict = (
  addressValidation: AddressValidationResponse,
): AddressVerificationOutput | null => {
  const uspsData = addressValidation.result?.uspsData!;
  const geocode = addressValidation.result?.geocode!.location;
  const unconfirmedComponentTypes =
    addressValidation.result?.address?.unconfirmedComponentTypes;
  const missingComponentTypes =
    addressValidation.result?.address?.missingComponentTypes;
  const formattedAddress = addressValidation.result?.address?.formattedAddress;

  const dpvConfirmation = uspsData?.dpvConfirmation;
  const standardizedAddress = uspsData?.standardizedAddress;

  const potentiallyWrong =
    (dpvConfirmation && dpvConfirmation === "N") ||
    (dpvConfirmation && dpvConfirmation === "D") ||
    Boolean(missingComponentTypes && missingComponentTypes.length > 0) ||
    Boolean(unconfirmedComponentTypes && unconfirmedComponentTypes.length > 0);

  // might not ultimately need all this, some is just for displaying on the screen during POC QA
  return {
    dpvConfirmation,
    standardizedAddress,
    geocode,
    formattedAddress,
    unconfirmedComponentTypes,
    missingComponentTypes,
    potentiallyWrong,
  };
};

export const line1Valid = (line1: string, country: string) => {
  if (line1.length > 44 || line1.length < 4) return false;
  if (/@/.test(line1)) return false;

  const isPoBox = /box/i.test(line1);
  const hasLetter = /[A-Za-z]/.test(line1);
  const requiresNumber = country !== "GB";
  const hasNumber = /\d/.test(line1);
  const satisfiesNumberRequirement = !requiresNumber || hasNumber;

  return (satisfiesNumberRequirement && hasLetter) || isPoBox;
};

export const basicModelValidations = (address: StripeFormattedAddress) => {
  const {
    country,
    line1,
    line2,
    city,
    state,
    postal_code: postalCode,
  } = address;

  const line1Ok = line1Valid(line1, country);
  const line2Ok = line2 ? line2.length <= 40 : true;
  const cityOk = city && city.length >= 2 && city.length <= 50;
  const stateOk =
    country === "GB" || (state && state?.length >= 2 && state?.length <= 50);
  const postalCodeOk = postalCode.length >= 5 && postalCode.length <= 10;
  const countryOk = ["US", "GB", "CA"].includes(country);

  return [line1Ok, line2Ok, cityOk, stateOk, postalCodeOk, countryOk].every(
    (el) => el,
  );
};

/**
 * Validates an address pulled from Stripe's Address Element, and validates with Google's address verification API
 * https://docs.stripe.com/elements/address-element
 * https://developers.google.com/maps/documentation/address-validation
 */
export const validateAddress = async (
  address: StripeFormattedAddress,
): Promise<AddressVerificationOutput | null | void> => {
  if (!basicModelValidations(address)) {
    return { potentiallyWrong: true };
  }
  const {
    country: countryInput,
    line1,
    line2,
    city,
    state,
    postal_code: postalCode,
  } = address;

  const country = countryInput === "GB" ? "UK" : countryInput;

  const response: AxiosResponse<AddressValidationResponse> = await axios.post(
    VALIDATE_ADDRESS_URL,
    {
      address: {
        regionCode: country,
        locality: city,
        addressLines: [line1, line2],
        administrativeArea: state,
        postalCode,
      },
    },
    {
      params: {
        key: GATSBY_GOOGLE_MAPS_API_KEY,
      },
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return addressValidationVerdict(response.data);
};

/**
 * Takes in arrays of what Google Maps considers missing or unconfirmed and returns
 * a legible list of things for the user to check
 */
export const addressSuggestions = (
  unconfirmedComponentTypes: string[] | null = [],
  missingComponentTypes: string[] | null = [],
): string | void => {
  const problematicAddressComponents = Array.from(
    new Set([
      ...(unconfirmedComponentTypes || []),
      ...(missingComponentTypes || []),
    ]),
  );
  if (problematicAddressComponents.length === 0) {
    return;
  }

  const addressComponentLookup: { [key: string]: string } = {
    street_number: "street number",
    route: "street name",
    administrative_area_level_1: "town or city",
    locality: "town or city",
    subpremise: "apartment number or suite",
    postal_code: "zip or postal code",
  };

  const readableComponents = problematicAddressComponents.map(
    (component) => addressComponentLookup[component],
  );

  return readableComponents.join(", ");
};
