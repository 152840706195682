import styled from "styled-components";
import CartProduct from "../../store/cart-product/model";
import CartItem from "../cart/CartItem";
import {
  getContentfulProductForPlan,
  getPlanForId,
} from "../../utils/planToProduct";
import Text from "../Text";

const CartItemsList = styled.div`
  & > *:last-child::before {
    display: none !important;
  }
`;

const CadenceTitle = styled.h3`
  position: relative;
  z-index: 10;
  margin: -1px 0 0 0;
  color: var(--indigo-blue-60);
  background-color: var(--white);
  display: flex;
  align-items: center;

  span.title-text {
    flex: 0 0 auto;
    padding-right: var(--spacing-0_5);
  }

  span.title-line {
    width: 100%;
    flex: 1 1 auto;
    border-top: 1px solid var(--cool-40);
  }
`;

interface LedgerItemsProps {
  activeCartProducts: CartProduct[];
}

const getIntervalCount = (cartProduct: CartProduct) => {
  let intervalCount;
  if (cartProduct?.planId) {
    const plan = getPlanForId(cartProduct.planId);
    intervalCount = plan.intervalCount || 0;
  }
  if (cartProduct?.productOfferId) {
    // TODO: Interval Count can be assumed 30 for now
    intervalCount = 30;
  }
  return intervalCount;
};

const LedgerItems = ({ activeCartProducts }: LedgerItemsProps) => {
  if (!activeCartProducts || activeCartProducts.length < 1) return null;

  const cartQuantity = activeCartProducts.reduce(
    (acc, cartProduct) => acc + cartProduct.quantity,
    0,
  );

  const addIntervalCountToCartProducts = activeCartProducts.map(
    (cartProduct) => {
      return {
        ...cartProduct,
        intervalCount: getIntervalCount(cartProduct),
      };
    },
  );
  const sortedData = addIntervalCountToCartProducts.sort(
    (a, b) => a.intervalCount - b.intervalCount,
  );

  const result: any[] = [];
  let lastIntervalCount: null | number = null;

  sortedData.forEach((item) => {
    if (item.intervalCount !== lastIntervalCount) {
      result.push(
        <CadenceTitle
          key={`title-${item.intervalCount}`}
          className="typography-caption"
        >
          <span className="title-text">
            <Text
              id={`checkout.cart-ledger.cadence-title-${item.intervalCount}`}
              defaultMessage=""
            />
          </span>
          <span className="title-line" />
        </CadenceTitle>,
      );
      lastIntervalCount = item.intervalCount;
    }
    const contentfulProduct = getContentfulProductForPlan(item?.planId);
    result.push(
      <CartItem
        key={item.key}
        className={"simplified"}
        cartProduct={item}
        contentfulProduct={contentfulProduct}
        isProcessing={false}
        cartQuantity={cartQuantity}
        simplified={true}
        isFlyoutContext={false}
        hideDeleteButton={true}
        disableQuantitySelector={true}
      />,
    );
  });

  return <CartItemsList>{result}</CartItemsList>;
};

export default LedgerItems;
