import { useEffect, useState } from "react";
import styled from "styled-components";
import { StripeAddressElementOptions } from "@stripe/stripe-js";
import {
  AddressElement,
  PaymentElement,
  LinkAuthenticationElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeFormattedAddress } from "../../utils/types/validateAddressTypes";
import {
  tryApiPurchase,
  tryElementsSubmit,
  tryCreateConfirmationToken,
  tryHandleNextAction,
} from "./utils";
import RitualButton from "../global/RitualButton";
import { navigate } from "../../services/navigation";
import { Color } from "../../utils/styleDesignSystem";

import Cart from "../../store/cart/model";

const Title = styled.h2`
  margin-bottom: var(--spacing-1);

  &.spacing-top {
    margin-top: var(--spacing-3);
  }
`;

const TermsCheckbox = styled.input`
  margin-right: 10px;
`;

const GATSBY_GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY!;

const autocomplete = {
  mode: "google_maps_api",
  apiKey: GATSBY_GOOGLE_MAPS_API_KEY,
};

const addressOptions = {
  allowedCountries: ["US", "CA", "GB"],
  ...(GATSBY_GOOGLE_MAPS_API_KEY && { autocomplete }),
} as StripeAddressElementOptions;

interface StripeElementsCheckoutProps {
  setAddress: React.Dispatch<
    React.SetStateAction<StripeFormattedAddress | null>
  >;
  setShowErrorBanner: React.Dispatch<React.SetStateAction<boolean>>;
  address: StripeFormattedAddress | null;
  handleAddressChange: () => Promise<void>;
  cart: Cart;
  disableSubmit: boolean;
}

const StripeElementsCheckout = ({
  setAddress,
  setShowErrorBanner,
  address,
  handleAddressChange,
  cart,
  disableSubmit,
}: StripeElementsCheckoutProps) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [purchaseExecuting, setPurchaseExecuting] = useState(false);
  const [customerName, setCustomerName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    if (!elements || !cart) {
      return;
    }
    elements.update({
      mode: "subscription",
      amount: cart.total,
      currency: cart.currency,
      setupFutureUsage: null,
      paymentMethodTypes: ["card", "link"],
      appearance: {
        theme: "stripe",
        variables: {
          borderRadius: "4px",
          colorDanger: `${Color.errorRed}`,
          colorPrimary: `${Color.indigoBlue}`,
          colorSuccess: `${Color.successGreen}`,
          colorText: `${Color.indigoBlue}`,
          colorWarning: `${Color.warningOrange}`,
          fontFamily: "CircularXX",
          fontSizeBase: "16px",
          fontVariantLigatures: "normal",
          fontWeightBold: "500",
          fontWeightLight: "450",
          fontWeightMedium: "500",
          fontWeightNormal: "450",
          iconHoverColor: `${Color.indigoBlue40}`,
          iconChevronDownColor: `${Color.indigoBlue}`,
          iconChevronDownHoverColor: `${Color.indigoBlue40}`,
          iconCloseColor: `${Color.indigoBlue}`,
          iconCloseHoverColor: `${Color.indigoBlue40}`,
          iconColor: `${Color.indigoBlue}`,
        },
      },
    });
  }, [elements, cart]);

  const handleSubmit = async (event: any) => {
    try {
      setPurchaseExecuting(true);
      event.preventDefault();
      await handleAddressChange();

      if (disableSubmit || !stripe || !elements) {
        return;
      }

      const { error: submitError } = await tryElementsSubmit(elements);
      if (submitError) {
        return;
      }

      const { error: confirmationTokenError, confirmationToken } =
        await tryCreateConfirmationToken(stripe, elements);
      if (confirmationTokenError || !confirmationToken) return;

      const purchase = await tryApiPurchase({
        confirmationToken: confirmationToken.id,
        shippingAddress: address,
        cartId: cart.id,
        customerName: customerName,
        email: email,
      });

      if (purchase.requires_action) {
        const { error: handleNextActionError, paymentIntent } =
          await tryHandleNextAction(purchase.client_secret, stripe);
        if (handleNextActionError || !paymentIntent) return;
      }

      navigate(`/checkout/confirmation`);
    } catch {
      setShowErrorBanner(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Title className="typography-lead2">Your Details</Title>
      <LinkAuthenticationElement onChange={(e) => setEmail(e.value.email)} />
      <p>
        <TermsCheckbox
          type="checkbox"
          onChange={(e) => setAcceptedTerms(e.target.checked)}
        />
        I accept the terms
      </p>
      <Title className="typography-lead2 spacing-top">Delivery</Title>
      <AddressElement
        options={{ ...addressOptions, mode: "shipping" }}
        onChange={(e) => {
          setCustomerName(e.value.name);
          setAddress(e.value.address);
        }}
        onBlur={handleAddressChange}
      />
      <Title className="typography-lead2 spacing-top">Payment</Title>
      <PaymentElement
        options={{
          layout: {
            type: "accordion",
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false,
          },
        }}
      />

      <RitualButton
        disabled={!acceptedTerms || purchaseExecuting}
        onClick={handleSubmit}
      >
        {purchaseExecuting ? "..." : "Purchase"}
      </RitualButton>
    </form>
  );
};

export default StripeElementsCheckout;
