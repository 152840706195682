import styled from "styled-components";
import { Color } from "../../utils/styleDesignSystem";
import { responsive } from "../../utils/style";
import { centsToCurrencyString } from "../../utils/centsToCurrencyString";
import PulseIcon from "../animation/PulseIcon";

const Container = styled.div`
  display: flex;
  padding: var(--Between-Components-Only-spacing-0_75, 12px);
  align-items: flex-start;
  gap: var(--Between-Components-Only-spacing-0_75, 12px);
  align-self: stretch;
  background-color: ${Color.yellowOchre20};
  flex-direction: row;
  justify-content: flex-start;

  ${responsive.sm`
      justify-content: center;
      align-items: center;
  `};
`;

const IconContainer = styled.div`
  height: 100%;
  width: 16px;

  ${responsive.sm`
    display: flex;
    align-items: flex-start;
    align-items: center;
  `};
`;

const Text = styled.div``;

const SavingsBanner = ({ savingsAmount }: { savingsAmount: number }) => {
  return (
    <Container>
      <IconContainer>
        <PulseIcon />
      </IconContainer>
      <Text className="typography-body3 font-circular">
        {`You're saving ${centsToCurrencyString(
          savingsAmount,
        )} on your first order`}
      </Text>
    </Container>
  );
};

export default SavingsBanner;
