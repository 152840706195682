import styled from "styled-components";
import { AddressVerificationOutput } from "../../utils/types/validateAddressTypes";
interface QAAddressDisplayProps {
  addressValidationResult: AddressVerificationOutput;
}

const DocsLink = styled.a`
  color: blue;
  text-decoration: underline;
`;

/**
 * Temporarily display the usps standardized address and formatted address
 * to aid in QA of shipping address validation.
 */
const QAAddressDisplay = ({
  addressValidationResult,
}: QAAddressDisplayProps) => {
  const { standardizedAddress, dpvConfirmation, formattedAddress } =
    addressValidationResult;

  return (
    <div>
      {standardizedAddress && (
        <>
          <p>Your standardized address (US only): </p>
          <ul>
            <li>{standardizedAddress.firstAddressLine}</li>
            <li>{standardizedAddress.secondAddressLine}</li>
            <li>{standardizedAddress.city}</li>
            <li>{standardizedAddress.state}</li>
            <li>{standardizedAddress.zipCode}</li>
          </ul>
        </>
      )}
      {dpvConfirmation && (
        <>
          <p>
            Your DPV value (us only):
            <DocsLink
              href="https://developers.google.com/maps/documentation/address-validation/reference/rest/v1/TopLevel/validateAddress#uspsdata"
              target="_blank"
              rel="noreferrer"
            >
              (Docs)
            </DocsLink>{" "}
            :
          </p>
          <p>{addressValidationResult.dpvConfirmation}</p>
        </>
      )}

      {formattedAddress && (
        <>
          <p>your formatted address (everyone): </p>
          <p>{formattedAddress}</p>
        </>
      )}
    </div>
  );
};

export default QAAddressDisplay;
