import styled from "styled-components";

const RowElement = styled.div`
  &.grid-row-plp {
    row-gap: var(--between-components-modules-spacing-3, 48px) !important;

    @media (min-width: 750px /* Grid S Tablet */) {
      row-gap: var(--Between-Modules-Only-spacing-4, 64px) !important;
    }
  }

  &.grid-row-ingredients {
    @media (min-width: 786px /* Grid S Tablet */) {
      gap: 80px !important;
    }

    @media (min-width: 1024px /* Grid M Tablet */) {
      gap: 106px !important;
    }
  }
`;

const GridRow = ({
  children,
  addClass,
}: {
  children: React.ReactNode;
  addClass?: string;
}) => {
  return (
    <RowElement className={`r-grid-row ${addClass ? addClass : ""}`}>
      {children}
    </RowElement>
  );
};

export default GridRow;
