import { useEffect, useState } from "react";
import styled from "styled-components";

import { validateAddress } from "../../utils/validateAddress";
import {
  StripeFormattedAddress,
  AddressVerificationOutput,
} from "../../utils/types/validateAddressTypes";
import fetchInternal from "../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import cartSelectors from "../../store/cart/selectors";
import cartProductSelectors from "../../store/cart-product/selectors";

import Cart from "../../store/cart/model";
import PotentiallyProblematicAddressModal from "../../components/checkout/PotentiallyProblematicAddressModal";
import QAAddressDisplay from "../../components/checkout/QAAddressDisplay";
import StripeElementsCheckout from "../../components/checkout/StripeElementsCheckout";
import ErrorBanner from "../../components/checkout/ErrorBanner";
import SavingsBanner from "../../components/checkout/SavingsBanner";
import CartLedger from "../../components/checkout/CartLedger";
import { fetchCart } from "../../store/cart/actions";
import { GridContainer } from "@ritual/essentials-for-react";
import GridRow from "../../components/grid/GridRow";
import GridColumn from "../../components/grid/GridColumn";
import SimpleNav from "../../components/global/navigation/SimpleNav";
import { responsive } from "../../utils/style";

const PageBackground = styled.div`
  background-color: var(--warm-20);
  min-height: calc(100svh - 57px);

  @media (min-width: 750px) {
    padding: var(--spacing-3) 0;
  }

  ${responsive.md`
    min-height: calc(100svh - 69px);
  `}
`;

const LeftColumnSpacing = styled.div`
  @media (min-width: 933px) {
    max-width: 660px;
    margin-left: auto;
  }
`;

const RightColumnSpacing = styled.div`
  @media (min-width: 933px) {
    max-width: 432px;
  }
`;

const Button = styled.button`
  display: flex;
  width: 300px;
  background-color: green;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 20px;
  text-align: center;
`;

interface CartAddressPayload {
  shipping_address: StripeFormattedAddress;
  discount_code?: string;
  validated: boolean;
}

// TODO: Extract to util or cart redux action
const patchCart = async (payload: CartAddressPayload, cart: Cart) => {
  const response = await fetchInternal(`carts/${cart.id}`, {
    method: "PATCH",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    fetchOptions: {
      addCastleRequestToken: true,
    },
    body: JSON.stringify(payload),
  });

  return response;
};

const CheckoutPage = () => {
  const [addressValidationResult, setAddressValidationResult] =
    useState<AddressVerificationOutput | null | void>(null);
  const [address, setAddress] = useState<StripeFormattedAddress | null>(null);
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [discountCode /*, setDiscountCode */] = useState<string | null>(null);
  const [suggestionModalOpen, setSuggestionModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const dispatch = useDispatch();

  const activeCart = useSelector(cartSelectors.activeCart);
  const activeCartProducts = useSelector(
    cartProductSelectors.sortedActiveCartProducts,
  );

  useEffect(() => {
    setHasLoaded(true);
  }, []);

  const handleAddressChange = async () => {
    // early return if not complete at all
    if (!address?.line1 && !address?.city && !address?.state) {
      return;
    }

    const validationResult = await validateAddress(address);

    setAddressValidationResult(validationResult);

    if (validationResult?.potentiallyWrong) {
      setSuggestionModalOpen(true);
      setDisableSubmit(true);
      return;
    }

    setDisableSubmit(false);
    let payload: CartAddressPayload = {
      shipping_address: address,
      validated: true,
    };

    if (activeCart.discountCode) {
      payload.discount_code = activeCart.discountCode;
    } else if (discountCode) {
      payload.discount_code = discountCode;
    }

    await patchCart(payload, activeCart);
    await dispatch(fetchCart());
  };

  return (
    <>
      <SimpleNav />
      {activeCart?.discountAmount && activeCart?.discountAmount > 0 && (
        <SavingsBanner savingsAmount={activeCart?.discountAmount} />
      )}
      {showErrorBanner && <ErrorBanner />}

      <PageBackground>
        <GridContainer>
          <GridRow>
            <GridColumn
              xs={{ size: 6 }}
              s={{ size: 12 }}
              m={{ size: 12, hide: true }}
            >
              {hasLoaded && activeCart && activeCartProducts && (
                <CartLedger
                  activeCart={activeCart}
                  activeCartProducts={activeCartProducts}
                />
              )}
            </GridColumn>

            <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 7 }}>
              <LeftColumnSpacing>
                {hasLoaded && (
                  <StripeElementsCheckout
                    setShowErrorBanner={setShowErrorBanner}
                    cart={activeCart}
                    address={address}
                    handleAddressChange={handleAddressChange}
                    setAddress={setAddress}
                    disableSubmit={disableSubmit}
                  />
                )}
                <Button onClick={handleAddressChange}>
                  validate address / add coupon
                </Button>

                {addressValidationResult && (
                  <QAAddressDisplay
                    addressValidationResult={addressValidationResult}
                  />
                )}
              </LeftColumnSpacing>
            </GridColumn>

            <GridColumn
              xs={{ size: 6, hide: true }}
              s={{ size: 6, hide: true }}
              m={{ size: 5 }}
            >
              <RightColumnSpacing>
                {hasLoaded && activeCart && activeCartProducts && (
                  <CartLedger
                    activeCart={activeCart}
                    activeCartProducts={activeCartProducts}
                  />
                )}
              </RightColumnSpacing>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </PageBackground>

      <PotentiallyProblematicAddressModal
        addressValidationResult={addressValidationResult}
        suggestionModalOpen={suggestionModalOpen}
        onRequestClose={() => setSuggestionModalOpen(false)}
        unconfirmedComponentTypes={
          addressValidationResult?.unconfirmedComponentTypes
        }
        missingComponentTypes={addressValidationResult?.missingComponentTypes}
      />
    </>
  );
};

export default CheckoutPage;
