import { StripeElements, StripeError, Stripe } from "@stripe/stripe-js";

import fetchInternal from "../../../utils/fetch";
import { StripeFormattedAddress } from "../../../utils/types/validateAddressTypes";

// TODO: type these as we consolidate the back end response
type APIPurchase = {
  payment_intent: any;
  subscription: any[];
  requires_action: undefined;
};
type APIPurchaseRequiresAction = {
  requires_action: true;
  client_secret: string;
  payment_intent: any;
};
type APIPurchaseResponse = APIPurchase | APIPurchaseRequiresAction;

export const tryElementsSubmit = async (
  elements: StripeElements,
): Promise<{ success: boolean; error: StripeError | undefined }> => {
  const { error } = await elements.submit();
  const success = !error;

  return { success, error };
};

export const tryCreateConfirmationToken = async (
  stripe: Stripe,
  elements: StripeElements,
) => {
  const { confirmationToken, error } = await stripe.createConfirmationToken({
    elements,
  });

  if (error) {
    console.error("[confirmationTokenError]", error);
  }

  if (confirmationToken) {
    console.log("[confirmationToken]", confirmationToken);
  }

  return { confirmationToken, error };
};
export const tryApiPurchase = async ({
  confirmationToken,
  shippingAddress,
  cartId,
  customerName,
  email,
}: {
  confirmationToken: string;
  shippingAddress: StripeFormattedAddress | null;
  cartId: string;
  customerName: string | null;
  email: string | null;
}) => {
  const response: APIPurchaseResponse = await fetchInternal("purchase", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      confirmation_token: confirmationToken,
      shipping_address: { ...shippingAddress, name: customerName },
      cart_id: cartId,
      email,
    }),
  });

  console.log("[purchaseResponse]", response);

  return response;
};

export const tryHandleNextAction = async (
  clientSecret: string,
  stripe: Stripe,
) => {
  const { error, paymentIntent } = await stripe.handleNextAction({
    clientSecret,
  });

  if (error) {
    console.error("[handleNextActionError]", error);
  }

  if (paymentIntent) {
    console.log("[handleNextAction paymentIntent]", paymentIntent);
  }

  return { paymentIntent, error };
};
