export const ProductSKU = {
  EFW60: "EFW60",
  E50PLUS60: "E50PLUS60",
  EPRE60: "EPRE60",
  EPOST60: "EPOST60",
  EFM60: "EFM60",
  EFM50PLUS60: "EFM50PLUS60",
  EFTG60: "EFTG60",
  EFTB60: "EFTB60",
  EFKIDS90: "EFKIDS90",
  EPRO1815VAN: "EPRO18-15-VAN", // Protein 18+ Vanilla
  EPRO5015VAN: "EPRO50-15-VAN", // Protein 50+ Vanilla
  EPROPREG15VAN: "EPROPREG-15-VAN", // Protein Pregnancy & Postpartum Vanilla
  SYN30: "SYN30", // Synbiotic+
  SKNCVA0: "SKNCVA0", // HyaCera
  SLPC: "SLPC", // Sleep
  CHLC: "CHLC", // Choline
  OM3C: "OM3C", // Omega-3
  STRS: "STRS", // Stress
  EPRE60M: "EPRE60-M", // EPRE Mint Variant
  FERTL: "FERTL", // Fertility
};

export const ProteinSkus = [
  ProductSKU.EPRO1815VAN,
  ProductSKU.EPRO5015VAN,
  ProductSKU.EPROPREG15VAN,
];

export function getGenderFromProduct(product) {
  const { demographics } = product;
  if (demographics.includes("Women") && demographics.includes("Men")) {
    return "unisex";
  } else if (demographics.includes("Women")) {
    return "female";
  } else if (demographics.includes("Men")) {
    return "male";
  } else if (demographics.includes("Kids & Teens")) {
    return "unisex";
  } else {
    return "";
  }
}

export function getAgeGroupFromProduct(product) {
  const { ageRanges } = product;
  if (ageRanges.includes("4-12")) {
    return "kids";
  } else if (ageRanges.includes("13-17")) {
    return "teens";
  } else if (ageRanges.includes("18-49") || ageRanges.includes("50+")) {
    return "adult";
  } else {
    return "";
  }
}

export function getEssentialHeadline({
  subCategory,
  demographics,
  ageRanges,
  sku,
}) {
  if (subCategory === "Protein") {
    return "essential.protein";
  }
  if (ageRanges && ageRanges.includes("4-12")) {
    return "essential.kids";
  }
  if (ageRanges && ageRanges.includes("13-17")) {
    return "essential.teens";
  }
  if (demographics && demographics.includes("Men")) {
    return "essential.men";
  }
  if (sku?.includes("CHLC")) {
    return "essential.choline";
  }
  if (sku?.includes("OM3C")) {
    return "essential.omega";
  }
  if (sku?.includes("FERTL")) {
    return "essential.fertility";
  }
  return "essential.women";
}

export function getSeoCategoryFromProduct(subCategory) {
  if (subCategory === "Protein") {
    return "5702 - Health & Beauty > Health Care > Fitness & Nutrition > Nutrition Drinks & Shakes";
  }
  return "525 - Health & Beauty > Health Care > Fitness & Nutrition > Vitamins & Supplements";
}
