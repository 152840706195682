import styled from "styled-components";
import { Color } from "../../utils/styleDesignSystem";

import { Icons } from "../../utils/react-svg";
import { responsive } from "../../utils/style";

const Container = styled.div`
  display: flex;
  padding: var(--Between-Components-Only-spacing-0_75, 12px);
  align-items: flex-start;
  gap: var(--Between-Components-Only-spacing-0_75, 12px);
  align-self: stretch;
  background-color: ${Color.errorRed};
  flex-direction: row;
  justify-content: flex-start;
  z-index: 2;

  ${responsive.sm`
      justify-content: center;
      align-items: center;
  `};
`;

const IconContainer = styled.div`
  height: 100%;
  width: 16px;

  ${responsive.sm`
    display: flex;
    align-items: flex-start;
    align-items: center;
  `};
`;

const Text = styled.div`
  color: ${Color.white};
`;

const ErrorBanner = () => {
  return (
    <Container>
      <IconContainer>
        <Icons.AlertWarning />
      </IconContainer>
      <Text className="typography-body3 font-circular">
        An unexpected error occurred while submitting your order. Please try
        again or contact support if the issue persists.
      </Text>
    </Container>
  );
};

export default ErrorBanner;
